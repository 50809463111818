import { render, staticRenderFns } from "./delete.vue?vue&type=template&id=bc773660&scoped=true&"
import script from "./delete.vue?vue&type=script&lang=js&"
export * from "./delete.vue?vue&type=script&lang=js&"
import style0 from "@/assets/style/modal.css?vue&type=style&index=0&id=bc773660&scoped=true&lang=css&"
import style1 from "./delete.vue?vue&type=style&index=1&id=bc773660&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc773660",
  null
  
)

export default component.exports
<template>
  <modal
    :modalId="`modalServico`"
    :modalTitle="idService == '' ? 'Criar Serviço' : 'Editar Serviço'"
    :load="load"
    :typeLg="`modal_lg`"
    @closeModal="closeModal"
  >
    <ValidationObserver ref="formService" class="row" tag="form">
      <div class="tab-content" id="myTabContent">
        <tabs :tabs="tabList" @tabsMovie="tabsMovie" />

        <serviceDetails
          class="conteudo"
          :class="`show active`"
          :name.sync="superObj.name"
          :categoryId.sync="superObj.categoryId"
          :typeService.sync="superObj.type"
          :link.sync="superObj.link"
          :tags.sync="superObj.tags"
          :description.sync="superObj.descriptionService"
        />
        <serviceInstructions
          class="conteudo"
          :instructions.sync="superObj.instructions"
          :hasPopup.sync="superObj.hasPopup"
          @getCheckBox="getCheckBox"
        />
        <serviceValues
          class="conteudo"
          :value.sync="superObj.value"
          :secondValue.sync="superObj.secondValue"
          :listValues.sync="superObj.tenantsAndValues"
          :serviceName="superObj.name"
          :objId="objId"
          @updateListValues="updateListValues"
          @updateListArrayValues="updateListArrayValues"
          @removeListValues="removeListValues"
        />
        <!--<sallesRelational
          class="conteudo"
          :serviceRelationals.sync="superObj.serviceRelationals"
        />-->
        <serviceComissions
          class="conteudo"
          :serviceName="superObj.name"
          :listValues.sync="superObj.tenantsAndValues"
          :hasComission.sync="superObj.hasComission"
          :comissionFranchisee.sync="superObj.comissionFranchisee"
          :comissionMaster.sync="superObj.comissionMaster"
          :comissionSallesman.sync="superObj.comissionSallesman"
          @updateListValues="updateListValues"
          @removeListValues="removeListValues"
        />
        <actions
          :id="idService"
          :tabs.sync="tabList"
          :disabled.sync="disabled"
          @closeModal="closeModal"
          @sendService="sendService"
          @openModalDeleteUnidades="openModalDeleteUnidades"
          @openModalDeleteService="openModalDeleteService"
          ref="actions"
        />
      </div>     
      <modalDeleteUnidades @removeItemsListValues="removeItemsListValues" @closeModalDeleteUnidades="closeModalDeleteUnidades"/>
      <modalDeleteService @excluirService="deleteService" @closeModalDeleteService="closeModalDeleteService" :serviceName="superObj.name"/>
    </ValidationObserver>
  </modal>
</template>

<script>
import tabs from "@/components/tabs/modalTabs.vue";
import modal from "@/components/modal/modalDefault.vue";
import actions from "./service_cp/acoes/botoes_rodape.vue";
import serviceDetails from "./service_cp/tabs/serviceDetails.vue";
import serviceInstructions from "./service_cp/tabs/serviceInstructions.vue";
import serviceValues from "./service_cp/tabs/serviceValues.vue";
import sallesRelational from "./service_cp/tabs/sallesRelational.vue";
import serviceComissions from "./service_cp/tabs/serviceComissions.vue";
import modalDeleteUnidades from "./service_cp/tabs/components/valuesAndComissions/modal/modalDeleteUnidades.vue";
import modalDeleteService from "./service_cp/tabs/components/valuesAndComissions/modal/modalDeleteService.vue";

export default {
  components: {
    modal,
    tabs,
    serviceDetails,
    serviceInstructions,
    serviceValues,
    sallesRelational,
    serviceComissions,
    modalDeleteUnidades,
    modalDeleteService,
    actions
  },
  props: ["idService"],
  data() {
    return {
      load: false,
      disabled: false,
      objId: "",
      superObj: {
        id: "",
        type: "",
        link: "",
        name: "",
        value: 0,
        secondValue: 0,
        descriptionService: "",
        cost: 0,
        videoUrl: "",
        hasPopup: false,
        categoryId: "",
        instructions: [],
        tenants: [],
        tags: [],
        serviceRelationals: [],
        tenantsAndValues: [],
        hasComission: false,
        comissionFranchisee: "",
        comissionMaster: "",
        comissionSallesman: ""
      },
      tabList: [
        {
          description: "Detalhes",
          tabName: "serviceDetails",
          show: true
        },
        {
          description: "Passo a Passo",
          tabName: "serviceInstructions",
          show: false
        },
        {
          description: "Valores e Unidades",
          tabName: "serviceValues",
          show: false
        },
        {
          description: "Comissão",
          tabName: "serviceComissions",
          show: false
        }
        // ,
        // {
        //   description: "Vendas Relacionadas",
        //   tabName: "sallesRelational",
        //   show: false
        // }
        // {
        //   description: "Comissão",
        //   tabName: "comission",
        //   show: false
        // }
      ]
    };
  },
  mounted() {
    if (this.idService != "") {
      this.getEditService();
    } else {
      this.load = false;
    }
  },
  methods: {
    sendService() {
      this.$refs.formService.validate().then(success => {
        if (success) {
          this.disabled = true;
          if (this.idService) {
            this.$store
              .dispatch("UpdateService", this.superObj)
              .then(s => {
                this.disabled = false;
                this.closeModal();
                this.toast("Serviço editado com sucesso!", this.$toast.success);
                this.$store.dispatch("getServices");
              })
              .catch(err => {
                this.toast("Ops, ocorreu um erro", this.$toast.error);
                this.disabled = false;
              });
          } else {
            this.$store
              .dispatch("CreateService", this.superObj)
              .then(s => {
                this.disabled = false;
                this.closeModal();
                this.toast(
                  "Serviço cadastrado com sucesso!",
                  this.$toast.success
                );
                this.$store.dispatch("getServices");
              })
              .catch(err =>
                this.toast("Ops, ocorreu um erro", this.$toast.error)
              );
          }
        } else {
          this.getTabFailed();
        }
      });
    },
    getEditService() {
      this.load = true;
      this.$store
        .dispatch("getService", this.idService)
        .then(res => {
          this.load = false;
          this.superObj = res;
        })
        .catch(err => console.log(err));
    },
    deleteService() {
      this.disabled = true;
      this.$store
        .dispatch("DeleteService", this.idService)
        .then(res => {
          this.toast("Serviço excluido", this.$toast.error);
          this.$nextTick(() => {
            this.closeModalDeleteService();
            this.closeModal();
            this.disabled = false;
            this.$store.dispatch("getServices");
          });
        })
        .catch(err => {
          this.toast("Ocorreu um erro ao excluir o serviço", this.$toast.error);
          this.disabled = false;
        });
    },
    closeModal() {
      $("#modalServico").modal("hide");
      this.$emit("closeModal");
    },
    getTabFailed() {
      var elements = document.getElementsByClassName("failed");
      if (elements.length) {
        var arrayElements = [...elements];
        var selected = arrayElements[0];
        var found = false;
        while (!found) {
          if (selected.parentNode.id === "") {
            selected = selected.parentNode;
          } else {
            selected = document.getElementById(`${selected.parentNode.id}-tab`);
            if (selected != undefined) {
              selected.click();
              found = true;
            }
          }
        }
      }
    },
    tabsMovie(index) {
      this.$refs.actions.showTabCreate(index);
    },
    getCheckBox(event) {
      event.parentNode.childNodes[0].childNodes[1].click();
    },
    updateListValues(obj) {
      let index = this.superObj.tenantsAndValues.findIndex(
        s => s.tenantId == obj.tenantId
      );

      if (index > -1) {
        if (obj.value != undefined) {
          this.superObj.tenantsAndValues[index].value = obj.value;
        }
        if (obj.secondValue != undefined) {
          this.superObj.tenantsAndValues[index].secondValue = obj.secondValue;
        }

        if (obj.comissionFranchisee != undefined) {
          this.superObj.tenantsAndValues[index].comissionFranchisee =
            obj.comissionFranchisee;
        }

        if (obj.comissionMaster != undefined) {
          this.superObj.tenantsAndValues[index].comissionMaster =
            obj.comissionMaster;
        }

        if (obj.comissionSallesman != undefined) {
          this.superObj.tenantsAndValues[index].comissionSallesman =
            obj.comissionSallesman;
        }
      } else {
        obj = {
          id: obj.id,
          tenantId: obj.tenantId,
          tenanancyName: obj.tenanancyName,
          value: obj.value !== undefined ? obj.value : null,
          secondValue: obj.secondValue !== undefined ? obj.secondValue : null,
          comissionFranchisee:
            obj.comissionFranchisee !== undefined
              ? obj.comissionFranchisee
              : null,
          comissionMaster:
            obj.comissionMaster !== undefined ? obj.comissionMaster : null,
          comissionSallesman:
            obj.comissionSallesman !== undefined
              ? obj.comissionSallesman
              : null,
          serviceId: this.idService
        };

        this.superObj.tenantsAndValues.push(obj);
      }

      this.toast(
        `${obj.id == "" ? "Adicionado" : "Atualizado"}`,
        this.$toast.info
      );
    },
    updateListArrayValues(obj) {
        let index = this.superObj.tenantsAndValues.findIndex(
          s => s.tenantId == obj.tenantId
        );

        if (index > -1) {
        if (obj.value != undefined) {
          this.superObj.tenantsAndValues[index].value = obj.value;
        }
        if (obj.secondValue != undefined) {
          this.superObj.tenantsAndValues[index].secondValue = obj.secondValue;
        }
      } else {
        obj = {
          id: obj.id,
          tenantId: obj.tenantId,
          tenanancyName: obj.tenanancyName,
          value: obj.value !== undefined ? obj.value : null,
          secondValue: obj.secondValue !== undefined ? obj.secondValue : null,
          serviceId: this.idService
        };

        this.superObj.tenantsAndValues.push(obj);

        this.objId = obj.id;
      }  
    },
    removeListValues(tenantName) {
      let indexRemove = this.superObj.tenantsAndValues.findIndex(
        s => s.tenanancyName == tenantName
      );
      if (indexRemove > -1) {
        this.superObj.tenantsAndValues.splice(indexRemove, 1);
        this.toast(`Unidade ${tenantName} Removida`, this.$toast.success);
      }
    },
    removeItemsListValues(){
      let tenantNames = [];
      let indexRemove;
      $("#listServiceValues input:checked").each(function(){
        tenantNames.push(this.id);
      });

      tenantNames.forEach(name => {
          indexRemove = this.superObj.tenantsAndValues.findIndex(
            s => s.tenanancyName == name
          );
          if (indexRemove > -1) {
            this.superObj.tenantsAndValues.splice(indexRemove, 1);
          }
      });

      $("#listServiceValues input:checked").each(function(){
        this.checked = false;
      });
      this.closeModalDeleteUnidades();
      this.toast(`Unidades Removidas`, this.$toast.success);
    },
    openModalDeleteUnidades() {
      if($("#listServiceValues input:checked").length > 0) $("#modalDeleteUnidades").modal("show");
    },
    closeModalDeleteUnidades() {
        $("#modalDeleteUnidades").modal("hide");
    },
    openModalDeleteService() {
      $("#modalDeleteService").modal("show");
    },
    closeModalDeleteService() {
        $("#modalDeleteService").modal("hide");
    }
  },
  computed: {
    tenant() {
      return this.$store.getters.tenantRequire;
    }
  },
  watch: {
    // idService: function() {
    //   if (this.idService != "") {
    //     this.getEditService();
    //   } else {
    //     this.load = false;
    //   }
    // }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media (max-width: 230px) {
  #myTabContent {
    max-width: 200;
    overflow-x: auto;
    min-width: 115px;
  }
}

#myTabContent {
  width: 100%;
}

.conteudo {
  min-height: 450px;
  padding-top: 12px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-4">
      <p class="my-3 titulo_dash">
        <a hrf="#" @click.prevent="enterCp('' ,'categoria')">Categorias</a> /
        <strong>{{suporte.name}}</strong>
      </p>
    </div>
    <div v-if="profile == 1" class="col-md-3 ml-auto">
      <button
        class="btn_default d-flex justify-content-center align-items-center"
        data-toggle="modal"
        @click.prevent="showModal(null)"
      >
        <i class="fas fa-plus-circle mr-1"></i>
        Add Suporte
      </button>
    </div>
    <div class="col-md-12">
      <div class="d-flex">
        <div class="col-md-3">
          <p class="mb-2 px-3">Suporte</p>
        </div>
      </div>
      <div class="box h-100">
        <div
          class="box_content h-100"
          :style="suporte.listSuporte == '' ? `background-color: #357eb8;` : ''"
        >
          <p
            class="my-5 text-center w-100"
            style="font-size:16px;"
            v-if="suporte.listSuporte == ''"
          >Não existem suportes para essa categoria</p>

          <transition-group name="list-complete" tag="div">
            <div
              class="box_item list-complete-item"
              v-for="(item,index) in listSuporte"
              :key="index + 1"
              @mouseenter="enterItem(index)"
              @mouseleave="exitItem(index)"
            >
              <div class="d-flex align-items-center h-100">
                <div class="col-md-3">
                  <p class="mb-0 px-3">{{item.name}}</p>
                </div>

                <div class="col-md-5 opacity" :class="`opacity_${index}`">
                    <div class="row align-items-center">
                      <div v-if="profile == 1" class="col-md-6">
                        <a @click.prevent="showModal(item.id)">
                          Editar
                          <i class="ml-2 fas fa-pen"></i>
                        </a>
                      </div>
                      <div class="col-md-6">
                        <router-link
                            :to="'/suporte/'+item.id"
                            exact-active-class="active"
                            class="navegation_item d-flex align-items-center justify-content-center"
                          >
                            Ir para o suporte
                            <i class="ml-2 fas fa-sign-in-alt"></i>
                        </router-link>
                      </div>
                    </div>
                  </div>
                

              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <component :is="cpModal" :category.sync="category" :idService.sync="idService" />
  </div>
</template>

<script>
import modal from "./modal/modalSuporte";
export default {
  components: { modal },
  props: ["id"],
  data() {
    return {
      category: "",
      idService: "",
      cpModal: ""
    };
  },
  mounted() {
    this.category = { id: this.suporte.id, name: this.suporte.name };
  },
  methods: {
    enterItem(index) {
      $(`.opacity_${index}`).fadeIn(500);
    },
    exitItem(index) {
      $(`.opacity_${index}`).fadeOut(500);
    },
    enterCp(id, name) {
      this.$emit("enterCp", id, name);
    },
    showModal(id) {
      this.cpModal = "modal";
      this.$nextTick(() => {
        this.idService = id;
        if (this.profile == 1) 
          $("#modalSuporte").modal("show");
      });
    }
  },
  computed: {
    suporte() {

      return this.$store.state.suporte.suporteCategoria.find(
        item => item.id == this.id
      );
    },
    listSuporte(){
      return this.suporte.listSuporte.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    profile() {
      return this.$store.getters.getUser.profile;
    }
  }
};
</script>

<style scoped>
.opacity {
  display: none;
}

.box_item >>> p {
  font-size: 14px;
}
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border-color: #357eb8;
  border-style: solid;
  border-width: 0px 3px 0px 3px;
  max-height: 60vh;
}
.box_content {
  overflow: auto;
  border-radius: 18px;
}
.box_item {
  height: auto;
  padding: 15px 0;
  cursor: pointer;
}
.box_item:nth-child(odd) {
  background-color: #357eb8;
}
.box_item:nth-child(even) {
  background-color: #175d93;
}

p,
a {
  color: #ffffff;
}
a {
  cursor: pointer;
  transition: all 0.2s linear;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
</style>

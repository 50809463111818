<template>
	<div class="row align-items-center">
		<div class="col-md-4 mb-0 pl-0">
			<p class="titulo_dash mb-0 d-flex align-items-center">{{nameModal}}</p>
		</div>
		<validationProvider
			tag="div"
			class="col-md-7  pr-0"
			v-slot="{ errors, ariaMsg, classes }"
			name="Link"
			:rules="type == 1 ? {required: true,  regex: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ } : ''"
		>
			<div class="row align-items-center">
				<div class="col-md-2 text-center">
					<label
						class="mb-0 label_default"
						:class="type ==1 ? classes : ''"
						:style="type == 2 ? 'color: #868686!important;' : ''"
					>Link</label>
				</div>
				<div class="col-md-10">
					<input
						type="text"
						:disabled="type ==2 ? true : false"
						class="input_default form-control"
						:class="type ==1 ? classes : 'disabled'"
						:value="link"
						@input="$emit('update:link', $event.target.value)"
					/>
				</div>
				<div class="col-md-12 text-center">
					<span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
				</div>
			</div>
		</validationProvider>
		<!-- <hr class="w-100" /> -->
	</div>
</template>

<script>
	export default {
		props: ["type", "link", "nameModal"],
		data() {
			return {};
		},
	};
</script>

<style scoped>
label.failed {
	color: #dc3545 !important;
}
hr {
	border-top: 2px solid #f4f4f4 !important;
}
.label_default {
	font-size: 14px;
	color: #2474b2 !important;
	font-weight: 500;
}
.titulo_dash {
	color: #2474b2;
	font-weight: 700;
}
.input_default {
	height: 35px;
}
</style>

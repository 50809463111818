<template>
  <div class="col-md-12" id="allCont">
    <div class="row align-items-center">
      <div class="col-md-4">
        <p class="my-3 titulo_dash">Categorias e Metas</p>
      </div>
      <div class="col-md-3 ml-auto" id="buttonCont">
        <button
          class="btn_default d-flex justify-content-center align-items-center"
          v-if="profile == 1"
          @click.prevent="showModal(null, null)"
        >
          <i class="fas fa-plus-circle mr-1"></i>Add Categoria
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" id="allCatCont">
        <div class="d-flex mb-2" id="catCont">
          <div class="col-md-3">
            <p class="px-3 mb-0">Categoria</p>
          </div>
          <div class="col-md-2 text-center">
            <p class="mb-0">Meta Volume</p>
          </div>
          <div class="col-md-3 text-center">
            <p class="mb-0 px-3">Meta Valor Líquida</p>
          </div>
        </div>
        <div class="box h-100">
          <div
            class="box_content h-100"
            :style="!servicos ? 'background: #357eb8;' : ''"
          >
            <p
              v-if="!servicos"
              class="my-5 text-center w-100"
              style="font-size:16px; color:#ffff;"
            >
              Ainda não existem categorias cadastradas
            </p>

            <transition-group name="list-complete" tag="div">
              <div
                class="box_item list-complete-item"
                v-for="(item, index) in servicos"
                :key="index + 1"
                @mouseenter="enterItem(index)"
                @mouseleave="exitItem(index)"
              >
                <div class="d-flex align-items-center h-100">
                  <div class="col-md-3" id="catName">
                    <p class="mb-0 px-3">{{ item.name }}</p>
                  </div>
                  <div class="col-md-2 text-center" id="catVolume">
                    <p class="mb-0">{{ item.goalVolume | currency }}</p>
                  </div>
                  <div class="col-md-3 text-center" id="catValor">
                    <p class="mb-0 px-3">{{ item.goalNet | currency }}</p>
                  </div>
                  <div class="col-md-4 opacity" :class="`opacity_${index}`">
                    <div class="row align-items-center">
                      <div class="col-md-6">
                        <a
                          @click.prevent="showModal(item.id, item.name)"
                          v-if="profile == 1"
                        >
                          Editar
                          <i class="ml-2 fas fa-pen"></i>
                        </a>
                      </div>
                      <div class="col-md-6">
                        <a hrf="#" @click.prevent="enterCp(item.id, 'servico')">
                          Ver serviços
                          <i class="ml-2 fas fa-sign-in-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <modal :id.sync="id" :name.sync="name" />
  </div>
</template>

<script>
import modal from "./modal/modalCategoria";
export default {
  components: {
    modal
  },
  data() {
    return {
      id: "",
      name: ""
    };
  },
  mounted() {},
  methods: {
    enterItem(index) {
      $(`.opacity_${index}`).fadeIn(200);
    },
    exitItem(index) {
      $(`.opacity_${index}`).fadeOut(100);
    },
    enterCp(id, name) {
      this.$emit("enterCp", id, name);
    },
    showModal(id, name) {
      this.id = id;
      this.name = name;
      $("#modalCategoria").modal("show");
    }
  },
  computed: {
    servicos() {
      return this.$store.state.servicos.servicosCategoria;
    },
    profile() {
      return this.$store.getters.getUser.profile;
    }
  }
};
</script>

<style scoped>
.opacity {
  display: none;
}

.box_item >>> p {
  font-size: 14px;
}
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border-color: #357eb8;
  border-style: solid;
  border-width: 0px 3px 0px 3px;
  max-height: 60vh;
}
.box_item {
  height: auto;
  padding: 15px 0;
  cursor: pointer;
}
.box_item:nth-child(odd) {
  background-color: #357eb8;
}
.box_item:nth-child(even) {
  background-color: #175d93;
}

p,
a {
  color: #ffffff;
}
a {
  cursor: pointer;
  transition: all 0.2s linear;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default:disabled {
  color: #e3e3e3 !important;
  background-color: #e3e3e321 !important;
}
.box_content {
  overflow: auto;
  border-radius: 18px;
}
@media screen and (max-width:767px){
  #catCont{
    margin-top: 5%;
  }
  #catName{
    min-width: 140px;
    max-width: 140px;
  }
  .box{
    max-height: 50vh;
    margin-bottom: 20%;
  }
  #allCatCont{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  #allCont{
    padding-left: 11px!important;
    padding-right: 11px!important;
  }
}
@media screen and (max-height:700px){
  #catCont{
    margin-top: 5%;
  }
  #catName{
    min-width: 125px;
    max-width: 125px;
  }
}
</style>

<template>
  <div class="col-md-12">
    <div class="row align-items-center">
      <div class="col-md-6 termTxtCont">
        <p class="mb-0 titulo_dash">Cadastro de Terminais Tef</p>
      </div>
      <div class="col-md-3 ml-auto">
        <button
          class="btn_default d-flex justify-content-center align-items-center"
          @click.prevent="showModal(null)"
        >
          <i class="fas fa-plus-circle mr-1"></i>Add Terminal
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 fullCont">
        <div class="d-flex mb-2 idAndFranqCont">
          <div class="col-md-3">
            <p class="px-3 mb-0">#Id Terminal</p>
          </div>
          <div class="col-md-3 text-center franquiaTXT">
            <p class="mb-0">Franquia</p>
          </div>
        </div>
        <div class="box" :style="load ? 'height:250px;' : 'min-height:auto;'">
          <loader color="#e0a638" size="100" v-if="load" />
          <div class="box_content w-100 h-100" v-if="this.terminaisTef != null">
            <p
              v-if="this.terminaisTef.length == 0"
              class="my-5 text-center w-100"
              style="font-size:16px;"
            >
              {{ this.msgNotFound }}
            </p>
            <transition-group name="list-complete" tag="div">
              <div
                class="box_item list-complete-item"
                v-for="(terminal, index) in terminaisTef"
                :key="index + 1"
                @mouseenter="enterItem(index)"
                @mouseleave="exitItem(index)"
              >
                <div class="d-flex align-items-center h-100">
                  <div class="col-md-3">
                    <p class="mb-0 px-3">{{ terminal.terminalZigNet }}</p>
                  </div>
                  <div class="col-md-3 text-center">
                    <p class="mb-0">{{ terminal.tenantName }}</p>
                  </div>
                  <div
                    v-if="!checkMobile()"
                    :class="`col-md-3 text-center opacity opacity_${index}`"
                  >
                    <a class="btn-edit" @click.prevent="showModal(terminal.id)">
                      <i :class="`ml-2 far fa-edit`"> Editar</i>
                    </a>
                  </div>
                  <div v-else :class="`col-md-3 text-center`">
                    <a class="btn-edit" @click.prevent="showModal(terminal.id)">
                      <i :class="`ml-2 far fa-edit`"></i>
                    </a>
                  </div>
                  <div class="col-md-3 text center">
                    <i class="fas fa-circle"></i>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <modal
      :cp="cp"
      :terminal="selecionado"
      @GetTerminaisVinculados="GetTerminaisVinculados"
    />
    <modalDegustacao/>
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
import modal from "./terminalTef/cpModal";
import modalDegustacao from "@/components/modal/modalDegustacao";

export default {
  components: {
    loader,
    modal,
    modalDegustacao
  },
  data() {
    return {
      msgNotFound: "",
      terminaisTef: [],
      selecionado: "",
      cp: "",
      load: true
    };
  },
  created() {
    this.GetTerminaisVinculados();
  },
  methods: {
    GetTerminaisVinculados() {
      this.$nextTick(() => {
        this.$store
          .dispatch("GetAllVinculados", { vinculed: true })
          .then(resolve => {
            this.$nextTick(() => {
              this.terminaisTef = resolve;
              this.load = false;
              if (resolve == "") {
                this.msgNotFound = "Ainda não existem terminais cadastrados";
              } else {
                this.msgNotFound = "";
              }
            });
          })
          .catch(reject => {
            this.load = false;
            this.msgNotFound = "Ops algo deu errado";
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
            });
          });
      });
    },
    enterItem(index) {
      $(`.opacity_${index}`).fadeIn(200);
    },
    exitItem(index) {
      $(`.opacity_${index}`).fadeOut(100);
    },
    checkMobile() {
      if (window.innerWidth < 767) {
        return true;
      } else {
        return false;
      }
    },
    showModal(id) {
      this.selecionado = {};
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        if (id === null) {
          this.cp = "Create";
        } else {
          this.selecionado = this.terminaisTef.find(s => s.id == id);
          this.cp = "Edit";
          console.log(this.selecionado);
        }

        this.$nextTick(() => {
          $(`#modal`).modal("show");
        })
      };
    },
    CloseModal() {
      $(`#modal`).modal("hide");
      this.cp = "";
    }
  }
};
</script>

<style scoped>
.box_content {
  overflow: auto;
  border-radius: 18px;
  max-height: 250px;
}
.opacity {
  display: none;
}
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}

.box_item >>> p {
  font-size: 14px;
}
.box {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border-color: #357eb8;
  background: #357eb8;
  border-style: solid;
  max-height: 250px;
  border-width: 0px 3px 0px 3px;
}
.box_item {
  height: auto;
  padding: 15px 0;
  cursor: pointer;
}
.box_item:nth-child(odd) {
  background-color: #357eb8;
}
.box_item:nth-child(even) {
  background-color: #175d93;
}

p,
a {
  color: #ffffff;
}
a {
  cursor: pointer;
  transition: all 0.2s linear;
}
a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* .box_item:hover {
  height: 50px;
} */
.btn-edit:hover {
  color: #f3823f;
}
@media screen and (max-width:767px){
  .termTxtCont{
    margin-bottom: 3%;
  }
  .btn_default{
    margin-bottom: 5%;
  }
  .fullCont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 109%!important;
  }
  .franquiaTXT{
    text-align: unset!important;
    margin-right: 14%!important;
  }
  .idAndFranqCont{
    width: 100%;
  }
}
</style>

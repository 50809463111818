<template>
  <div
    class="tab-pane fade col-md-12"
    id="serviceDetails"
    role="tabpanel"
    aria-labelledby="serviceDetails-tab"
  >
    <div class="row">
      <ValidationProvider
        tag="div"
        class="col-md-12 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        name="Nome do serviço"
        :rules="{
          required: true,
          max: 50,
          regex: /[a-zA-Z\u00C0-\u00FF ]+/i
        }"
      >
        <label class="mb-1 label_default" :class="classes">
          Nome do Serviço
        </label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="name"
          maxlength="50"
          @input="$emit('update:name', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        class="col-md-12 mb-1 form-group form-outline"
        rules="required"
        name="Categoria"
      >
        <label class="mb-1 label_default" :class="classes">Categoria</label>
        <div class="input-group">
          <select
            class="form-control input_default"
            :class="classes"
            :value="categoryId"
            @change="$emit('update:categoryId', parseInt($event.target.value))"
          >
            <option
              :value="item.id"
              v-for="(item, index) in listCategories"
              :key="index"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        class="col-md-7 mb-1 form-group form-outline"
        rules="required"
        name="Tipo"
      >
        <label class="mb-1 label_default" :class="classes">Tipo</label>
        <div class="input-group">
          <select
            class="form-control input_default"
            :class="classes"
            :value="typeService"
            @change="$emit('update:typeService', parseInt($event.target.value))"
          >
            <option
              :value="item.value"
              v-for="(item, index) in listTypeService"
              :key="index"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-5 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        name="Link"
        :rules="
          typeService == 1
            ? {
                required: true,
                regex: getRegex
              }
            : ''
        "
      >
        <label
          class="mb-1 label_default"
          :class="typeService == 1 ? classes : ''"
          :style="typeService == 2 ? 'color: #868686!important;' : ''"
        >
          Link
        </label>
        <input
          class="form-control input_default"
          :class="typeService == 1 ? classes : ''"
          :value="link"
          @input="$emit('update:link', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="col-md-12 mt-3 mb-1 form-group form-outline">
        <label class="label_default label_blue">
          Tags Relacionadas
        </label>
        <input-tag v-model="tagOptions" />
      </div>
      <div class="col-md-12 mb-1 form-group form-outline">
        <label class="mb-1 label_default">
          Descrição
        </label>
        <textarea
          class="form-control textarea_default w-100"
          style="max-height:70px;"
          :value="description"
          @input="$emit('update:description', $event.target.value)"
          maxlength="400"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";

export default {
  props: ["name", "categoryId", "typeService", "link", "tags", "description"],
  directives: { mask },
  components: { HalfCircleSpinner, TheMask },
  methods: {},
  data() {
    return {
      tagOptions: []
    };
  },
  mounted() {
    this.tagOptions = this.tags.map(t => t.tagName);
  },
  computed: {
    listTypeService() {
      return this.$store.state.servicos.servicesType;
    },
    listCategories() {
      return this.$store.state.servicos.servicosCategoria.map(cat => ({
        id: cat.id,
        name: cat.name
      }));
    },
    getRegex() {
      return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    }
  },
  watch: {
    tagOptions: function() {
      var newTags = this.tagOptions.map(t => ({
        id: this.tags.filter(mt => mt.tagName == t)?.map(mt => mt.id)[0],
        tagName: t
      }));
      this.$emit("update:tags", newTags);
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style>
.vue-input-tag-wrapper .input-tag {
  background-color: #fff !important;
  border-radius: 14px;
  border: 2px solid #f9f9f9;
  color: #797984;
  display: inline-block;
  font-weight: 400;
  size: 14px;
  line-height: 21px;
}
.vue-input-tag-wrapper .input-tag .remove {
  cursor: pointer;
  font-weight: 700;
  color: #797984;
}
.vue-input-tag-wrapper .new-tag {
  background: transparent;
  border: 0;
  color: #797984;
  size: 14px;
  font-weight: 400;
  font-family: "poppins";
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 4px;
  padding-left: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.vue-input-tag-wrapper {
  background-color: #fff;
  border: 1px solid #98c4e6;
  border-radius: 6px !important;
  overflow: hidden;
  padding-left: 4px;
  padding-top: 4px;
  cursor: text;
  text-align: left;
  -webkit-appearance: textfield;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
</style>

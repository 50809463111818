import { render, staticRenderFns } from "./categoria_suporte.vue?vue&type=template&id=67083284&scoped=true&"
import script from "./categoria_suporte.vue?vue&type=script&lang=js&"
export * from "./categoria_suporte.vue?vue&type=script&lang=js&"
import style0 from "./categoria_suporte.vue?vue&type=style&index=0&id=67083284&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67083284",
  null
  
)

export default component.exports
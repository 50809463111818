var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 px-0"},[_c('ValidationObserver',{ref:"instrucaoRef",staticClass:"row",attrs:{"tag":"div"}},[_c('hr',{staticClass:"w-100"}),_c('div',{staticClass:"col-md-12 px-0"},[_c('div',{staticClass:"row align-items-center mb-3"},[_c('div',{staticClass:"col-md-3"},[_c('p',{staticClass:"titulo_dash mb-0"},[_vm._v("Passo a passo")])]),_c('div',{staticClass:"col-md-5"},[_c('ValidationProvider',{staticClass:"form__group field",attrs:{"tag":"div","rules":"max:60","name":"Titulo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.title),expression:"item.title"}],staticClass:"form__field",class:classes,attrs:{"type":"input","placeholder":"Titulo","name":"Titulo","id":"Titulo"},domProps:{"value":(_vm.item.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "title", $event.target.value)}}}),_c('label',{staticClass:"form__label",class:classes,attrs:{"for":"Titulo"}},[_vm._v("Titulo")]),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"col-md-3 pl-0"},[_c('div',{staticClass:"box_image position-relative"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.item.image.includes('data')  ? _vm.item.image : _vm.getImg(_vm.item.image)},on:{"error":_vm.errorImage2}}),_c('input',{staticClass:"btnAlteraFoto position-absolute",attrs:{"type":"file","id":"file","accept":"image/x-png, image/gif, image/jpeg","placeholder":"Alterar"},on:{"change":_vm.onChoseFile}})])]),_c('ValidationProvider',{staticClass:"col-md-9 px-0",attrs:{"tag":"div","rules":"max:200","name":"Descricao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.description),expression:"item.description"}],staticClass:"w-100 mb-0 px-2 py-2",class:classes,domProps:{"value":(_vm.item.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "description", $event.target.value)}}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-md-4 px-0"},[_c('span',_vm._b({staticClass:"contador",class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(_vm.item.description.length)+"/ 200")])]),_c('div',{staticClass:"col-md-4 ml-auto px-0 text-right"},[_c('a',{staticClass:"mb-0 p_destaque",class:_vm.item.spotlight ? 'p_destaque_active' : 'p_destaque',on:{"click":function($event){$event.preventDefault();return _vm.selectDestaque($event)}}},[_vm._v(" Destaque "),_c('i',{staticClass:"fas fa-star"})])])]),_c('div',{staticClass:"col-md-12 px-0 my-3"},[_c('button',{staticClass:"btn_default_excluir btn_default",on:{"click":function($event){$event.preventDefault();return _vm.exclude(_vm.item)}}},[_vm._v(" Excluir "),_c('i',{staticClass:"fas fa-trash-alt ml-1"})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
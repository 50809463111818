<template>
    <modal
      :modalId="`modalDeleteUnidades`"
      :modalTitle="'Remover Unidade'"
      :load="false"
      :typeLg="`modal_md`"
    >
      <div class="col-md-12 mb-1 form-group form-outline">
        <label class="label_default label_blue label_sm">
            Deseja realmente excluir as unidades selecionadas?
        </label>
      </div>
      <div class="row m-auto col-md-12">
    <div class="col-md-9 ml-auto">
      <div class="row">
        <div class="col-md-2 m-auto">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModalDeleteUnidades')"
          >
            Cancelar
          </button>
        </div>
        <div class="col-md-4 m-auto">
          <button
            type="button"
            @click="$emit('removeItemsListValues')"
            class="btn btn_delete"
          >
            Excluir
          </button>
        </div>
      </div>
    </div>
  </div>
    </modal>
  </template>
  
  <script>
  export default {
    props: [],
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
    },
    methods: { }
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  <style scoped>
  .b_danger {
    color: red;
  }
  </style>
import { render, staticRenderFns } from "./botao_rodape.vue?vue&type=template&id=11b33cee&scoped=true&"
import script from "./botao_rodape.vue?vue&type=script&lang=js&"
export * from "./botao_rodape.vue?vue&type=script&lang=js&"
import style0 from "./botao_rodape.vue?vue&type=style&index=0&id=11b33cee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b33cee",
  null
  
)

export default component.exports
<template>
  <div class="row align-items-center">
    <validationProvider
      tag="div"
      class="col-md-12 px-0"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Nome do Suporte"
    >
      <label class="label_default my-3" :class="classes">Nome do suporte</label>
      <input
        type="text"
        class="input_default form-control"
        :class="classes"
        :value="name"
        @input="$emit('update:name', $event.target.value)"
      />
    </validationProvider>

    <validationProvider
      tag="div"
      class="col-md-6 pl-0 mt-3"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Fornecedor"
    >
      <label class="label_default my-3" :class="classes">Fornecedor</label>
      <input
        type="text"
        class="input_default form-control"
        :class="classes"
        :value="supplier"
        @input="$emit('update:supplier', $event.target.value)"
      />
    </validationProvider>

     <validationProvider
      tag="div"
      class="col-md-6 pr-0 mt-3"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Nome do atendente"
    >
      <label class="label_default my-3" :class="classes">Nome do atendente</label>
      <input
        type="text"
        class="input_default form-control"
        :class="classes"
        :value="clerk"
        @input="$emit('update:clerk', $event.target.value)"
      />
    </validationProvider>

    <validationProvider
      tag="div"
      class="col-md-6 pl-0"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Telefone"
    >
      <label class="label_default my-3" :class="classes">Telefone</label>
      <input
        type="number"
        class="input_default form-control"
        :class="classes"
        :value="phone"
        @input="$emit('update:phone', $event.target.value)"
      />
    </validationProvider>

    <validationProvider
      tag="div"
      class="col-md-6 pr-0"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Telefone secundário"
    >
      <label class="label_default my-3" :class="classes">Telefone secundário</label>
      <input
        type="number"
        class="input_default form-control"
        :class="classes"
        :value="secondaryPhone"
        @input="$emit('update:secondaryPhone', $event.target.value)"
      />
    </validationProvider>

    <validationProvider
      tag="div"
      class="col-md-12 px-0"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="email"
    >
      <label class="label_default my-3" :class="classes">email</label>
      <input
        type="text"
        class="input_default form-control"
        :class="classes"
        :value="email"
        @input="$emit('update:email', $event.target.value)"
      />
    </validationProvider>

    
    
  </div>
</template>

<script>
export default {
  props: ["name","supplier","clerk","phone","secondaryPhone","email"]
};
</script>

<style scoped>
hr {
  border-top: 2px solid #f4f4f4 !important;
}
.input_default {
  height: 35px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}
label.failed {
  color: #dc3545 !important;
}
</style>
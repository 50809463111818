var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-pane fade col-md-12",attrs:{"id":"serviceDetails","role":"tabpanel","aria-labelledby":"serviceDetails-tab"}},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-12 mb-1 form-group form-outline",attrs:{"tag":"div","name":"Nome do serviço","rules":{
        required: true,
        max: 50,
        regex: /[a-zA-Z\u00C0-\u00FF ]+/i
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var ariaMsg = ref.ariaMsg;
      var classes = ref.classes;
return [_c('label',{staticClass:"mb-1 label_default",class:classes},[_vm._v(" Nome do Serviço ")]),_c('input',{staticClass:"form-control input_default",class:classes,attrs:{"maxlength":"50"},domProps:{"value":_vm.name},on:{"input":function($event){return _vm.$emit('update:name', $event.target.value)}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-md-12 mb-1 form-group form-outline",attrs:{"tag":"div","rules":"required","name":"Categoria"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var ariaMsg = ref.ariaMsg;
      var classes = ref.classes;
return [_c('label',{staticClass:"mb-1 label_default",class:classes},[_vm._v("Categoria")]),_c('div',{staticClass:"input-group"},[_c('select',{staticClass:"form-control input_default",class:classes,domProps:{"value":_vm.categoryId},on:{"change":function($event){_vm.$emit('update:categoryId', parseInt($event.target.value))}}},_vm._l((_vm.listCategories),function(item,index){return _c('option',{key:index,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),0)]),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-md-7 mb-1 form-group form-outline",attrs:{"tag":"div","rules":"required","name":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var ariaMsg = ref.ariaMsg;
      var classes = ref.classes;
return [_c('label',{staticClass:"mb-1 label_default",class:classes},[_vm._v("Tipo")]),_c('div',{staticClass:"input-group"},[_c('select',{staticClass:"form-control input_default",class:classes,domProps:{"value":_vm.typeService},on:{"change":function($event){_vm.$emit('update:typeService', parseInt($event.target.value))}}},_vm._l((_vm.listTypeService),function(item,index){return _c('option',{key:index,domProps:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),0)]),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-md-5 mb-1 form-group form-outline",attrs:{"tag":"div","name":"Link","rules":_vm.typeService == 1
          ? {
              required: true,
              regex: _vm.getRegex
            }
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var ariaMsg = ref.ariaMsg;
          var classes = ref.classes;
return [_c('label',{staticClass:"mb-1 label_default",class:_vm.typeService == 1 ? classes : '',style:(_vm.typeService == 2 ? 'color: #868686!important;' : '')},[_vm._v(" Link ")]),_c('input',{staticClass:"form-control input_default",class:_vm.typeService == 1 ? classes : '',domProps:{"value":_vm.link},on:{"input":function($event){return _vm.$emit('update:link', $event.target.value)}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"col-md-12 mt-3 mb-1 form-group form-outline"},[_c('label',{staticClass:"label_default label_blue"},[_vm._v(" Tags Relacionadas ")]),_c('input-tag',{model:{value:(_vm.tagOptions),callback:function ($$v) {_vm.tagOptions=$$v},expression:"tagOptions"}})],1),_c('div',{staticClass:"col-md-12 mb-1 form-group form-outline"},[_c('label',{staticClass:"mb-1 label_default"},[_vm._v(" Descrição ")]),_c('textarea',{staticClass:"form-control textarea_default w-100",staticStyle:{"max-height":"70px"},attrs:{"maxlength":"400"},domProps:{"value":_vm.description},on:{"input":function($event){return _vm.$emit('update:description', $event.target.value)}}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
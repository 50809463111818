import { render, staticRenderFns } from "./serviceInstructions.vue?vue&type=template&id=65d593ca&scoped=true&"
import script from "./serviceInstructions.vue?vue&type=script&lang=js&"
export * from "./serviceInstructions.vue?vue&type=script&lang=js&"
import style0 from "@/assets/style/modal.css?vue&type=style&index=0&id=65d593ca&scoped=true&lang=css&"
import style1 from "./serviceInstructions.vue?vue&type=style&index=1&id=65d593ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d593ca",
  null
  
)

export default component.exports
<template>
  <modal
    :modalId="`modalDelete`"
    :modalTitle="'Remover Unidade'"
    :load="false"
    :typeLg="`modal_md`"
    @closeModal="closeModal"
  >
    <div class="col-md-12 mb-1 form-group form-outline">
      <label class="label_default label_blue label_sm"
        >Deseja realmente excluir a unidade
        <b class="b_danger"> {{ tenanancyName }} </b>
        <br />
        do servico
        <b class="b_danger">{{ serviceName }}</b>
      </label>
      <actions
        :nameButton="'Excluir'"
        @closeModal="closeModal"
        @submitform="submitform"
      />
    </div>
  </modal>
</template>

<script>
export default {
  props: ["serviceName", "tenanancyName"],
  components: {
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () => import("../acoes/botoes_rodape.vue")
  },
  methods: {
    submitform() {
      this.$emit("removeTenant", this.tenanancyName);
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", "modalDelete");
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.b_danger {
  color: red;
}
</style>

<template>
  <modal
    :modalId="`modal`"
    :modalTitle="
      tenanancyName == ''
        ? 'Adicionar Unidade e Comissao'
        : 'Editar Comissao da Unidade'
    "
    :load="load"
    :typeLg="`modal_md`"
    @closeModal="closeModal()"
  >
    <ValidationObserver ref="formValueService" class="row" tag="form">
      <ValidationProvider
        tag="div"
        class="col-md-12 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="serviceTenantId == '' ? { required: true } : ''"
        name="Unidade"
      >
        <label class="mb-1 label_default" :class="classes">Unidade</label>
        <input
          v-if="tenanancyName != ''"
          class="form-control input_default"
          :class="classes"
          :value="tenanancyName"
          disabled
        />
        <div class="input-group" v-else>
          <select
            class="form-control input_default"
            :class="classes"
            :value="tenantId"
            @change="$emit('update:tenantId', $event.target.value)"
          >
            <option disabled selected />
            <option
              :value="item.id"
              v-for="(item, index) in tenants"
              :key="index"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="{
          max: 12
        }"
        class="col-md-5 mb-1 form-group form-outline"
        name="Comissao Master"
      >
        <label class="mb-1 label_default" :class="classes">C. Master</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="comissionMaster"
          maxlength="12"
          @keypress="checkValueOnly($event)"
          @input="$emit('update:comissionMaster', $event.target.value)"
          @blur="
            $event.target.value != ''
              ? $emit(
                  'update:comissionMaster',
                  valueConvertBR(parseFloat($event.target.value).toFixed(2))
                )
              : ''
          "
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="{
          max: 12
        }"
        class="col-md-5 mb-1 form-group form-outline"
        name="Comissao Franqueado"
      >
        <label class="mb-1 label_default" :class="classes">C. Franqueado</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="comissionFranchisee"
          maxlength="12"
          @keypress="checkValueOnly($event)"
          @input="$emit('update:comissionFranchisee', $event.target.value)"
          @blur="
            $event.target.value != ''
              ? $emit(
                  'update:comissionFranchisee',
                  valueConvertBR(parseFloat($event.target.value).toFixed(2))
                )
              : ''
          "
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <actions
        :nameButton="tenanancyName == '' ? 'Criar' : 'Salvar'"
        @closeModal="closeModal()"
        @submitform="submitform"
      />
    </ValidationObserver>
  </modal>
</template>

<script>
export default {
  props: [
    "serviceTenantId",
    "tenantId",
    "tenanancyName",
    "tenants",
    "load",
    "comissionSallesman",
    "comissionFranchisee",
    "comissionMaster"
  ],
  components: {
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () => import("../acoes/botoes_rodape.vue")
  },
  data() {
    return {
      disabled: false
    };
  },
  methods: {
    submitform() {
      this.$refs.formValueService.validate().then(success => {
        if (success) {
          if (this.tenants.length) {
            const tenant = this.tenants.find(s => s.id == this.tenantId);
            this.$emit("update:tenantId", tenant.id);
            this.$emit("update:tenanancyName", tenant.name);
          }

          if (this.comissionFranchisee == undefined) {
            this.$emit("update:comissionFranchisee", null);
          }
          if (this.comissionMaster == undefined) {
            this.$emit("update:comissionMaster", null);
          }

          this.$emit("submitform");
          this.closeModal();
        }
      });
    },
    closeModal() {
      this.$emit("closeModal", "modal");
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>

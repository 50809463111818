<template>
	<div class="row">
		<hr class="w-100" />
		<div class="col-md-12 px-0">
			<p class="titulo_dash">Vídeo Youtube</p>
			<div class="input-group">
				<div class="input-group-prepend">
					<div class="input-group-text">https://www.youtube.com/watch?v=</div>
				</div>
				<input
					type="text"
					class="input_default form-control"
					v-on:input="$emit('update:videoUrl', $event.target.value)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["videoUrl"],
		data() {
			return {
				link: "",
			};
		},
		watch: {
			link: function () {
				this.$emit("update:videoUrl", this.link);
			},
		},
	};
</script>

<style scoped>
.titulo_dash {
	color: #2474b2;
	font-weight: 500;
}
.input_default {
	height: auto;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.input-group-text {
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	font-size: 14px;
	background-color: #868686;
	color: #ffff;
	font-weight: 500;
}
</style>

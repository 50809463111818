import { render, staticRenderFns } from "./suporte.vue?vue&type=template&id=702c60a3&scoped=true&"
import script from "./suporte.vue?vue&type=script&lang=js&"
export * from "./suporte.vue?vue&type=script&lang=js&"
import style0 from "./suporte.vue?vue&type=style&index=0&id=702c60a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702c60a3",
  null
  
)

export default component.exports
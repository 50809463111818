<template>
  <ValidationObserver class="row" ref="form" tag="form">
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default">#Terminal</label>
      <input
        type="text"
        class="input_default form-control"
        disabled
        :value="terminal.terminalZigNet"
      />
    </div>
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default">Unidade</label>
      <input
        type="text"
        class="input_default form-control"
        disabled
        :value="terminal.tenantName"
      />
    </div>
    <ValidationProvider
      tag="div"
      class="col-md-4 mb-3"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Mensagem de Apresentação PinPad"
    >
      <label class="mb-1 label_default" :class="classes"
        >Mensagem Apresentação</label
      >
      <input
        type="text"
        class="input_default form-control"
        v-model="terminal.messagePinPad"
        :class="classes"
      />
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <ValidationProvider
      tag="div"
      class="col-md-4 mb-3 form-group form-outline"
      v-slot="{ errors, ariaMsg, classes }"
      rules="required"
      name="Selecione a Porta"
    >
      <label class="mb-1 label_default" :class="classes">Selecione Porta</label>
      <select
        class="input_default form-control"
        :class="classes"
        v-model="terminal.portPinPad"
      >
        <option disabled />
        <option
          v-for="(item, index) in pinPadPorts"
          :key="index"
          :value="item.id"
        >
          {{ item.port }}
        </option>
      </select>
      <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
    </ValidationProvider>
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default">Verificar Conexão PinPad</label>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="checkPinPad"
          v-model="terminal.checkPinPad"
        />
        <label class="form-check-label" for="checkPinPad">Sim</label>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <label class="mb-1 label_default"
        >Usar Pagamento QRCode *Desabilitado*</label
      >
      <div class="form-check">
        <input
          type="checkbox"
          id="checkQrCode"
          class="form-check-input"
          disabled
        />
        <label class="form-check-label" for="checkQrCode">Sim</label>
      </div>
    </div>
    <Acoes :edit="true" :dados="terminal" />
  </ValidationObserver>
</template>
<script>
import Acoes from "./acoes/botao_rodape.vue";

export default {
  props: ["terminal", "pinPadPorts"],
  components: { Acoes },
  data() {
    return {
      disabled: false
    };
  }
};
</script>

<style scoped>
.failed span {
  font-size: 10px !important;
}
.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
.modal_custom {
  max-width: 740px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}

label.failed {
  color: #dc3545 !important;
}

.input_default {
  height: 45px;
}

.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
</style>

<template>
  <modal
    :modalId="`modal`"
    :modalTitle="
      tenanancyName == ''
        ? 'Adicionar Unidade e Preço'
        : 'Editar Preço da Unidade'
    "
    :load="load"
    :typeLg="`modal_md`"
    @closeModal="closeModal()"
  >
    <ValidationObserver ref="formValueService" class="row" tag="form">
      <ValidationProvider
        tag="div"
        class="col-md-12 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="serviceTenantId == '' ? { required: true } : ''"
        name="Unidade"
      >
        <label class="mb-1 label_default" :class="classes">Unidade</label>
        <input
          v-if="tenanancyName != ''"
          class="form-control input_default"
          :class="classes"
          :value="tenanancyName"
          disabled
        />
        <div class="input-group" v-else>
          <multiselect
              v-model="valueTenant"
              :options="tenantOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :searchable="false"
              :maxHeight="150"
              :limit="1"
              :limit-text="limitText"
              placeholder="Selecionar Unidades"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="valueTenant.length && !isOpen"
                  >
                  {{ 
                    valueTenant.length > 1
                      ? `${valueTenant.length} selecionados`
                      : `${valueTenant.length} selecionado`
                  }}
                </span>
              </template>
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="{
          max: 12
        }"
        class="col-md-6 mb-1 form-group form-outline"
        name="Valor"
      >
        <label class="mb-1 label_default" :class="classes">Valor</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="value"
          maxlength="12"
          @keypress="checkValueOnly($event)"
          @input="$emit('update:value', $event.target.value)"
          @blur="
            $event.target.value != ''
              ? $emit(
                  'update:value',
                  valueConvertBR(parseFloat($event.target.value).toFixed(2))
                )
              : ''
          "
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="{
          max: 12
        }"
        class="col-md-6 mb-1 form-group form-outline"
        name="Valor Secundário"
      >
        <label class="mb-1 label_default" :class="classes"
          >Valor Secundário</label
        >
        <input
          class="form-control input_default"
          :class="classes"
          :value="secondValue"
          maxlength="12"
          @keypress="checkValueOnly($event)"
          @input="$emit('update:secondValue', $event.target.value)"
          @blur="
            $event.target.value != ''
              ? $emit(
                  'update:secondValue',
                  valueConvertBR(parseFloat($event.target.value).toFixed(2))
                )
              : ''
          "
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <actions
        :nameButton="tenanancyName == '' ? 'Criar' : 'Salvar'"
        @closeModal="closeModal()"
        @submitform="submitform()"
      />
    </ValidationObserver>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: [
    "serviceTenantId",
    "tenantId",
    "tenanancyName",
    "value",
    "secondValue",
    "tenants",
    "load",
    "comissionSallesman",
    "comissionFranchisee",
    "comissionMaster"
  ],
  components: {
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () => import("../acoes/botoes_rodape.vue")
  },
  data() {
    return {
      disabled: false,
      valueTenant: [],
      valueStatus: [],
      statusOptions: [],
      loadingStatus: true,
    };
  },
  computed: {
    ...mapGetters(["tenantRequire"]),
    tenantOptions() {
      if (this.tenants != null && this.tenants.length) {
        return this.tenants.map(t => ({ id: t.id, name: t.name }));
      }
      return [];
    }
  },
  methods: {
    submitform() {
      this.$refs.formValueService.validate().then(success => {
        if (success) {
          this.submit();
        }
      });
    },
    closeModal() {
      this.$emit("closeModal", "modal");
    },
    submit() {
      this.closeModal();
      if (this.valueTenant != null && this.valueTenant.length) {
        this.$emit("submitformValues", this.valueTenant, this.value, this.secondValue );
      } else {
        this.$emit("submitform");
      }
    },
    async getStatus() {
      try {
        const tenantId = this.tenantRequire.tenantId
          ? this.tenantRequire.tenantId
          : "";
        const { data } = await this.getStatusAndTags({ tenantId });
        const { result } = data;
        this.loadingStatus = false;
        if (result.length) {
          this.statusOptions = result;
        }
      } catch (error) {
        this.loadingStatus = false;
      }
    },
    limitText(count) {
      return `e mais ${count}`;
    }
  },
  mounted() {
    this.getStatus();
  },
  watch: {
      valueStatus() {
      if (this.valueStatus.length) {
        let groupNames = [
          ...new Set(this.valueStatus.map(t => t.groupName))
        ].map(t => ({
          groupName: t,
          statusTags: this.valueStatus
            .filter(vf => vf.groupName === t)
            ?.map(x => x.id)
        }));
      }
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>

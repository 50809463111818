<template>
  <div class="row m-auto col-md-12">
    <div class="col-md-3 mr-auto" v-if="dados.id !== undefined">
      <button
        class="btn_default d-flex justify-content-center align-items-center btn_default_excluir"
        :disabled="disabled"
        @click.prevent="Delete(false, dados.id)"
      >
        <i class="fas fa-trash-alt mr-1" v-if="!stageDelete"></i>
        <span
          v-else
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        Excluir
      </button>
    </div>
    <div class="col-md-3 ml-auto">
      <button
        class="btn_default d-flex justify-content-center align-items-center"
        :disabled="disabled"
        @click.prevent="SaveChanges()"
      >
        <i class="fas fa-plus-circle mr-1" v-if="!stageSave"></i>
        <span
          v-else
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        {{ (dados.id !== undefined) != "" ? "Editar" : "Cadastrar" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dados", "local"],
  data() {
    return {
      disabled: false,
      stageSave: false,
      stageDelete: false,
      dadosEdit: ""
    };
  },
  methods: {
    SaveChanges() {
      this.$parent.validate().then(success => {
        if (success) {
          this.disabled = this.stageSave = true;
          if (this.dados.id === undefined) {
            this.CreateServer(this.dados);
          } else {
            this.dadosEdit = {
              terminalZigNet: this.dados.terminalZigNet,
              portPinPad: this.dados.portPinPad,
              mensagemApresentacao: this.dados.messagePinPad,
              checkPinPad: this.dados.checkPinPad,
              tenantId: this.dados.tenantId
            };
            this.EditLocal();
          }
        }
      });
    },
    CreateServer(_dados) {
      this.$store
        .dispatch("CreateOrEditTerminalTef", _dados)
        .then(sRes => {
          this.CreateLocal(sRes.msg);
        })
        .catch(sError => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Erro ao cadastrar o terminal no Servidor!`
          });
        });
    },
    CreateLocal(_dados) {
      this.$store
        .dispatch("CreateTerminalTefLocal", _dados)
        .then(lRes => {
          this.$notify({
            group: "erros",
            type: "sucess",
            text: `<i class="icon ion-close-circled"></i>Terminal Cadastrado com Sucesso!`
          });
          this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
          this.CloseModal();
        })
        .catch(lError => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Erro ao Cadastrar o terminal Localmente!`
          });
          this.Delete(true, _dados.id);
        });
    },
    EditLocal() {
      this.$store
        .dispatch("EditTerminalTefLocal", this.dadosEdit)
        .then(lRes => {
          this.EditServer();
        })
        .catch(lError => {
          this.disabled = this.stageSave = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>${lError.msg}`
          });
        });
    },
    EditServer() {
      this.$store
        .dispatch("CreateOrEditTerminalTef", this.dados)
        .then(sRes => {
          this.stageSave = false;
          this.$notify({
            group: "erros",
            type: "success",
            text: `<i class="icon ion-close-circled"></i>Operação de alteração realizada com exito!`
          });
          this.CloseModal();
          this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
        })
        .catch(sError => {
          this.disabled = this.stageSave = false;
          this.CloseModal();
          this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Erro ao efetivar os dados na base, realize o procedimento novamente!`
          });
        });
    },
    Delete(_transaction, id) {
      this.disabled = this.stageDelete = true;
      this.$store
        .dispatch("DeleteTerminalTef", { id: id, transaction: _transaction })
        .then(t => {
          if (!_transaction) {
            this.DeleteTerminalLocal();
          } else {
            this.stageDelete = false;
            this.$notify({
              group: "erros",
              type: "warn",
              text: `<i class="icon ion-close-circled"></i>${t.msg}`
            });
            this.CloseModal();
          }
        })
        .catch(err => {
          this.disabled = this.stageDelete = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>${err.msg}`
          });
          if (!_transaction) {
            this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
          }
          this.CloseModal();
        });
    },
    DeleteTerminalLocal() {
      this.$store
        .dispatch("DeleteTerminalTefLocal")
        .then(t => {
          this.stageDelete = false;
          this.$notify({
            group: "erros",
            type: "success",
            text: `<i class="icon ion-close-circled"></i>${t.msg}`
          });
          this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
          this.CloseModal();
        })
        .catch(err => {
          this.disabled = this.stageDelete = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Não foi possivel excluir os arquivos do terminal localmente, faça o processo manualmente!`
          });
          this.$parent.$parent.$parent.$emit("GetTerminaisVinculados");
          this.CloseModal();
        });
    },
    CloseModal() {
      this.$parent.$parent.$parent.$parent.CloseModal();
    }
  }
};
</script>

<style scoped>
.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
</style>

<template>
  <div>
    <div class="modal fade" id="modalCategoria" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_custom" role="document">
        <div class="modal-content container">
          <div class="modal-body px-4 py-4">
            <p class="titulo_dash mb-4">{{id ? 'Editar Categoria':'Adicionar Categoria'}}</p>
            <ValidationObserver
              ref="formCategory"
              class="row"
              tag="form"
              @submit.prevent="sendUnidade()"
            >
              <ValidationProvider
                tag="div"
                class="col-md-12"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Nome da categoria"
              >
                <label class="mb-1 label_default" :class="classes">Nome da categoria</label>
                <input
                  type="text"
                  class="input_default form-control"
                  :value="name"
                  @input="$emit('update:name', $event.target.value)"
                  :class="classes"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="col-md-3 mt-4" v-if="id">
                <button
                  class="btn_default d-flex justify-content-center align-items-center btn_default_excluir"
                  :disabled="disabledDelete"
                  :class="disabled ? 'btn_default_disabled' : ''"
                  @click.prevent="excluirCategoria()"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="disabledDelete"
                  ></span>
                  <i class="fas fa-trash-alt mr-1" v-show="!disabledDelete"></i>
                  {{ !disabledDelete ?`Excluir` : "" }}
                </button>
              </div>
              <div class="col-md-3 ml-auto mt-4">
                <button
                  class="btn_default d-flex justify-content-center align-items-center"
                  :disabled="disabled || disabledDelete"
                  :class="disabledDelete ? 'btn_default_disabled' : ''"
                  type="submit"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="disabled"
                  ></span>
                  <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
                  {{ !disabled ?`${id ?'Editar': 'Criar'}` : "" }}
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["name", "id"],
  data() {
    return {
      disabled: false,
      disabledDelete: false
    };
  },
  mounted() {},
  methods: {
    sendUnidade() {
      this.$refs.formCategory.validate().then(success => {
        if (success) {
          this.disabled = true;
          if (this.id) {
            var json = { name: this.name, id: this.id };
          } else {
            var json = { name: this.name };
          }

          HTTP.post("services/app/Category/CreateOrEdit", json, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }).then(response => {
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Categoria editada`
            });
            this.$store.dispatch("getServices");
            this.$nextTick(() => {
              $("#modalCategoria").modal("hide");
              this.disabled = false;
            });
          });
        }
      });
    },

    excluirCategoria() {
      this.$refs.formCategory.validate().then(success => {
        if (success) {
          this.disabledDelete = true;
          HTTP.delete(`services/app/Category/Delete?Id=${this.id}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }).then(response => {
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Categoria excluida`
            });
            this.$store.dispatch("getServices");
            this.$nextTick(() => {
              $("#modalCategoria").modal("hide");
              this.disabledDelete = false;
            });
          });
        }
      });
    }
  },
  watch: {},
  computed: {}
};
</script>

<style scoped>
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.modal_custom {
  max-width: 620px;
}

.modal-content {
  border-radius: 20px;
}
.label_default {
  font-size: 14px;
  color: #2474b2;
  font-weight: 500;
}

.input_default {
  height: 45px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
</style>
<template>
  <div
    class="tab-pane fade col-md-12"
    id="serviceInstructions"
    role="tabpanel"
    aria-labelledby="serviceInstructions-tab"
  >
    <instruction
      :title.sync="instruct.title"
      :description.sync="instruct.description"
      :spotlight.sync="instruct.spotlight"
      :image.sync="instruct.image"
      @newInstruction="createInstruction"
    />
    <transition-group name="list-complete" tag="div" class="div_instructions">
      <instruction
        v-for="instruction in instructions"
        :key="instruction.sequence"
        :title.sync="instruction.title"
        :description.sync="instruction.description"
        :spotlight.sync="instruction.spotlight"
        :image.sync="instruction.image"
        :sequence="instruction.sequence"
        @delInstruction="removeInstruction"
      />
    </transition-group>
    <hr class="w-100" />
    <popup :withPopup.sync="withPopup" @getCheckBox="getCheckBox" />
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";

export default {
  props: ["instructions", "hasPopup"],
  directives: { mask },
  components: {
    HalfCircleSpinner,
    TheMask,
    instruction: () =>
      import("./components/serviceInstructions/instruction.vue"),
    popup: () => import("./components/serviceInstructions/popup.vue")
  },
  data() {
    return {
      instruct: {
        title: "",
        description: "",
        spotlight: false,
        image: "",
        sequence: this.instructions.length + 1
      },
      withPopup: false
    };
  },
  methods: {
    createInstruction() {
      if (this.instruct.title != "" && this.instruct.description) {
        console.log(this.instruct);
        this.toast("Passo Adicionado", this.$toast.success);
        var array = this.instructions;
        array.push(this.instruct);
        this.$emit("update:instructions", array);
        this.instruct = {
          image: "",
          title: "",
          description: "",
          spotlight: false,
          sequence: this.instructions.length + 1
        };
      } else {
        this.toast(
          "Favor preencher os campos de Titulo e Anotacoes para incluir Passo a Passo",
          this.$toast.info
        );
      }
    },
    removeInstruction(numSequence) {
      var currentInstructions = this.instructions;
      var index = currentInstructions.findIndex(s => s.sequence == numSequence);
      if (index > -1) {
        currentInstructions.splice(index, 1);
        this.toast("Passo Removido", this.$toast.success);
      }
    },
    getCheckBox(event) {
      this.$emit("getCheckBox", event);
    }
  },
  watch: {
    withPopup: function() {
      this.$emit("update:hasPopup", this.withPopup);
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
/* @media (width: 767px) {
  .box_image {
    width: 100px;
  }
} */

.div_instructions {
  max-height: 245px;
  overflow-x: hidden;
}
</style>
